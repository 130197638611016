.page-wrapper {
    font-family: "Skandinavisk", sans-serif;
    src: url('../../fonts/NoirSkandinavisk.woff2') format('woff2');
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-style: normal;
}

.message {
    text-align: center;
}

h1 {
    margin: 0 0 8px;
    font-size: 20px;
    line-height: 30px;
    font-style: italic;
    text-transform: uppercase;
    font-weight: normal;
}

h2 {
    margin: 0 0 8px;
    font-size: 25px;
    line-height: 30px;
    font-weight: normal;
}

h3 {
    margin: 0 0 8px;
    font-size: 22px;
    line-height: 30px;
    font-weight: normal;
}

h4 {
    margin: 0;
    font-size: 13px;
    line-height: 30px;
    font-weight: normal;
}
